
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




































































































































































































.jobs-listing__component {
  &:not(:last-child) {
    // prettier-ignore
    @include fluid(margin-bottom, (xxs: 50px, xxl: 100px));
  }
}
